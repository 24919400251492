@import "main-bx";

.banner {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  img {
    width: 100%;
  }
  //&-desktop {
  //  display: block;
  //  @include below(64em) {
  //    display: none;
  //  }
  //}
  //&-mobile {
  //  display: none;
  //  @include below(64em) {
  //    display: block;
  //  }
  //}
}
