@import "main-bx";

.intro {
  width: auto;
  background: #fff;
  height: 530px;
  @include below(31em) {
    position: relative;
    height: 366px;
  }
  .container {
    max-width: 1220px;
  }
  &__img {
    &-desktop {
      height: 530px;
      position: relative;
      @include below(31em) {
        display: none;
      }
      &::after {
        content: "";
        position: absolute;
        height: 530px;
        width: 100%;
        background: linear-gradient(180deg, #B01010 0%, rgba(176, 16, 16, 0.00) 100%);
        top: 0;
        left: 0;
        transform: rotate(180deg);
      }
    }
    &-mobile {
      display: none;
      height: 366px;
      position: relative;
      z-index: 40;
      @include below(31em) {
        display: block;
      }
    }
    img {
      position: relative;
      bottom: 0;
      object-position: top;
    }
  }
  &__content {
    position: relative;
    z-index: 50;
    width: 100%;
    margin-top: -280px;
    margin-bottom: 365px;
    @include below(64em) {
      margin-top: -300px;
      margin-bottom: 612px;
    }
  }
  &__title {
    color: $color_yellow;
    margin-bottom: .87rem;
    max-width: 70rem;
    @include below(64em) {
      margin-bottom: 1.5rem;
    }
    @include below(31em) {
      max-width: 19.625rem;
    }
  }
  &__buttons {
    @include below(37.5em) {
      display: flex;
      flex-direction: column;
    }
  }
  &__btn {
    &:not(:last-child) {
      margin-right: 1.69rem;
      @include below(37.5em) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
  &-2 {
    margin-bottom: 0;
    height: 818px;
    position: relative;
    @include below(31em) {
      height: 500px;
    }
    .intro__img {
      height: 818px;
      position: relative;
      @include below(31em) {
        height: 500px;
      }
      &::after {
        content: "";
        position: absolute;
        height: 818px;
        width: 100%;
        background: linear-gradient(180deg, #B01010 0%, rgba(176, 16, 16, 0.00) 100%);
        top: 0;
        left: 0;
        transform: rotate(180deg);
        @include below(31em) {
          height: 500px;
        }
      }
    }
    .intro__content {
      margin-top: -600px;
      @include below(31em) {
        margin-top: -380px;
      }
    }
    .intro__title {
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4.6rem;
      @include below(768px) {
        font-size: 2.7rem;
        line-height: 120%;
      }
      @include below(31em) {
        font-size: 2rem;
      }
      span {
        font-size: 10.625rem;
        @include below(768px) {
          font-size: 8rem;
        }
        @include below(31em) {
          font-size: 4rem;
        }
      }
    }
    .intro__text {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: $color_yellow;
      margin-bottom: 2rem;
      @include below(31em) {
        font-size: 1.125rem;
      }
    }
    .intro__btn {
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 1.375rem;
      line-height: 120%;
      padding: 10px 16px;
    }
  }
}
