@import "main-bx";

h1 {
  font-size: 4.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5.15625rem;
  color: $color_yellow;
  span {
    font-size: 6rem;
  }
  @include below(64em) {
    font-size: 2.6rem;
    line-height: 3.3rem;
    span {
      font-size: 3.2rem;
      line-height: 3.3rem;
    }
  }
  @include below(48em) {
    font-size: 2.25rem;
    line-height: 2.8125rem;
    span {
      font-size: 2.75rem;
      line-height: 2.8125rem;
    }
  }
}

h2 {
  font-size: 2.3125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5625rem;
  color: $color_red;
  margin-bottom: 2.5rem;
  text-align: center;
  @include below(64em) {
    font-size: 2.25rem;
    line-height: 2.8125rem;
    margin-bottom: 1.87rem;
  }
}

h3 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
  color: $color_red;
}

h4 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.0625rem;
  @include below(64em) {
    font-size: 1rem;
    line-height: 1.1875rem;
  }
}

.text {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem;
}

.text-table {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110.182%;
  color: #000;
  &-title {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 82.182%;
    color: #000;
    text-align: center;
    @include below(64rem) {
      font-size: 1.25rem;
      line-height: 104.7%;
    }
  }
}



