@import "main-bx";

.burger {
  display: none;
}

@media (max-width: 64em) {
  .burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 2.63rem;
    height: 1.27rem;
    flex-shrink: 0;
    position: relative;
    z-index: 11;
    cursor: pointer;
    span {
      height: .2712rem;
      background-color: $color_white;
      transition: all $trns;
      &:nth-child(1) {
        width: 2.625rem;
        margin-bottom: .725rem;
      }
      &:nth-child(2) {
        width: 2.2631rem;
      }
    }
    &_active {
      span {
        transition: $trns;
        &:nth-child(1) {
          width: 2rem;
          transform: rotate(45deg);
          margin-bottom: -.17rem;
        }
        &:nth-child(2) {
          width: 2rem;
          background-color: $color_white;
          transform: rotate(-45deg);
          margin-bottom: 0;
          margin-top: -.1rem;
        }
      }
    }
  }
}
