@import "main-bx";

.footer {
  background-color: #C6C6C7;
  padding-top: 90px;
  &__wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 110px;
    @include below(64em) {
      flex-wrap: wrap;
    }
    @include below(49.375em) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &__col {
    margin: 0 5px;
    flex-grow: 0;
    flex-shrink: 1;
    &-1 {
      flex-basis: 322px;
      margin-bottom: 40px;
      @include below(49.375em) {
        flex-basis: auto;
      }
    }
    &-2 {
      flex-basis: 388px;
    }
    &-3 {
      flex-basis: 260px;
      margin-top: 35px;
    }
  }
  &__title {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.0625rem;
    margin-bottom: 17px;
    @include below(64em) {
      text-align: center;
    }
  }
  &__text {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    @include below(64em) {
      text-align: center;
    }
    @include below(49.375em) {
      font-size: 1.125rem;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    @include below(64em) {
      align-items: center;
    }
    li {
      @include below(64em) {
        text-align: center;
      }
      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }
  }
  &__link {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #000;
    @include below(64em) {
      text-align: center;
    }
  }
  &__logo {
    display: flex;
    width: 13.1875rem;
    flex-shrink: 0;
    margin-bottom: 20px;
    @include below(64em) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__socials {
    display: flex;
    margin-bottom: 60px;
    @include below(64em) {
      justify-content: center;
    }
  }
  &__social {
    flex: 0 0 44px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__btn {
    width: fit-content;
    &:not(:last-child) {
      margin-bottom: 14px;
      width: 100%;
    }
  }
  &__bottom {
    background-color: $color_red;
    padding: 20px 0;
    &-text {
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      color: $color_white;
      text-align: center;
    }
  }
}
