@import "main-bx";

.nav {
  background-color: #313131;
  position: fixed;
  z-index: 100;
  width: 100%;
  @include below(980px) {
    height: 34px;
  }
  &__menu {
    //position: relative;
    //background-color: #313131;
    overflow: hidden;
    &-desktop {
      .nav__list {
        > li {
          margin-left: .78rem;
          &:not(:last-child) {
            margin-right: .78rem;
          }
        }
      }
      @include below(980px) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @include below(980px) {
        display: block;
        &.js-nav {
          width: 100%;
          position: fixed;
          z-index: 2;
          .swiper-wrapper {
            flex-wrap: nowrap;
            width: 100%;
            margin: 0 auto;
          }
          .swiper-slide {
            margin-left: 1rem;
            margin-right: 1rem;
          }
          .swiper-button-next {
            position: absolute;
            fill: #929292;
            width: 30px;
            height: 30px;
            transform: rotate(-90deg);
            z-index: 30;
            top: 24px;
            right: 30px;
            left: unset;
            background-color: #313131;
            &::after {
              font-size: 0;
              @include below(61.25em) {
                content: "";
                position: absolute;
                top: -80px;
                right: -60px;
                background: linear-gradient(270deg, #313131 19.27%, rgba(49, 49, 49, 0.00) 100%);
                width: 150px;
                height: 34px;
                z-index: 20;
                overflow: visible;
                transform: rotate(90deg);
              }
            }
            &.swiper-button-disabled {
              opacity: 0;
            }
          }
          .swiper-button-prev {
            position: absolute;
            fill: #929292;
            width: 30px;
            height: 30px;
            transform: rotate(90deg);
            z-index: 30;
            top: 24px;
            left: -2px;
            background-color: #313131;
            &::after {
              font-size: 0;
            }
            &.swiper-button-disabled {
              opacity: 0;
            }
          }
        }
      }
    }

  }
  &__list {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    > li {
      //&:not(:last-child) {
      //  margin-right: .78rem;
      //  margin-left: .78rem;
      //}
    }
  }
  &__link {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.125rem;
    color: $color_white;
    white-space: nowrap;
    &_active {
      color: $color_yellow;
    }
    &:hover {
      color: $color_yellow;
    }
  }
}
