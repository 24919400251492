@import "main-bx";

html {
    //scroll-behavior: smooth;
    //scroll-padding: 60px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul, ol, p {
    margin: 0;
    padding: 0;
}

.main {
    padding-top: 70.8px;
}

.container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
}

body {
    font-family: $font_main !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    width: 100%;
    max-width: $body_width;
    margin: 0 auto;
    background-color: #E6E7E8;
    color: $color_dark;
    overflow-x: hidden;
    @media (max-width: 55.625em) {
        font-size: 15px;
    }
    &.compensate-for-scrollbar {
        margin-right: auto !important;
    }
}

a {
    text-decoration: none;
}

input,
button,
textarea,
fieldset {
    background: transparent;
    border: 0;
    padding: 0;
}

button {
    cursor: pointer;
}

img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
}

ul {
    list-style-type: none;
}


:focus {
    outline-style: none;
    outline-width: 0 !important;
    outline-color: none !important;
}

.none {
    display: none;
}
