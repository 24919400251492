@import "modules/footer/footer";
@import "modules/header/header";

//UI
@import "modules/ui/indent"; //отступы
@import "modules/ui/grid-flex"; //сетка
@import "modules/ui/typo"; //типография
@import "modules/ui/social"; //социалки

@import "modules/btn/btn";
@import "modules/burger/burger";
@import "modules/nav/nav";

//компоненты
@import "modules/intro/intro";
@import "modules/content/content";
@import "modules/error/error";
@import "modules/banner/banner";
