@import "main-bx";

.content {
  &__wrap {
    display: flex;
    flex-direction: column;
  }
  &__block {
    position: relative;
    &-desktop {
      @include below(768px) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @include below(768px) {
        display: block;
      }
    }
  }
  &__text {
    &-block {
      flex-basis: 533px;
      @include below(768px) {
        flex-basis: unset;
      }
    }
  }
  &__img {
    flex-basis: 600px;
    height: fit-content;
    @include below(768px) {
      flex-basis: unset;
      height: auto;
    }
    &_min {
      flex-basis: 300px;
      height: fit-content;
      @include below(768px) {
        max-width: 300px;
        width: 100%;
        flex-basis: unset;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-center {
      width: 100%;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
    &-svg {
      position: absolute;
      z-index: -1;
      &_loop {
        right: 30px;
        top: -10px;
        @include below(768px) {
          top: 260px;
        }
      }
      &_ball {
        bottom: 0;
        left: 50%;
        @include below(768px) {
          top: 60%;
          bottom: unset;
        }
      }
      &_square {
        bottom: 0;
        left: 45%;
        @include below(768px) {
          bottom: unset;
          top: 200px;
        }
      }
      &_badge {
        bottom: -40px;
        right: 0;
      }
    }
  }
  &__col {
    flex-basis: 816px;
    @include below(768px) {
      flex-basis: unset;
    }
  }
  &__table {
    border: 1px solid $color_red;
    border-collapse: collapse;
    width: 100%;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    @include below(768px) {
      font-size: 1.25rem;
    }
    th {
      border: 1px solid $color_red;
      border-collapse: collapse;
      padding: 10px 20px;
    }
    td {
      width: 50%;
      border: 1px solid $color_red;
      border-collapse: collapse;
      padding: 17px 30px;
    }
    &-1 {
      @include below(768px) {
        font-size: 1rem;
      }
      th {
        padding: 10px;
      }
      td {
        padding: 10px;
      }
    }
    &-2 {
      margin-bottom: 20px;
      td {
        width: 67%;
      }
    }
    &-block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0;
      @include below(56.25em) {
        flex-direction: column;
      }
    }
    &-3 {
      flex-grow: 1;
      flex-shrink: 1;
      th {
        background-color: $color_red;
        color: #E6E7E8;
        @include below(71.5em) {
          height: 70px;
          padding: 10px 8px;
          font-size: 1.25rem;
        }
      }
      td {
        text-align: center;
        padding: 14px 23px;
        height: 77px;
        @include below(71.5em) {
          font-size: 1.25rem;
          padding: 14px 17px;
        }
      }
      &-1 {
        width: 40%;
        flex-basis: 40%;
        @include below(56.25em) {
          width: 100%;
        }
        td {
          width: 50%;
        }
      }
      &-2 {
        width: 60%;
        flex-basis: 60%;
        @include below(56.25em) {
          width: 100%;
        }
        td {
          width: 50%;
          &:first-child {
            width: 30%;
            @include below(56.25em) {
              width: 30%;
            }
          }
        }
      }
    }
    &-wrap {
      overflow-x: scroll;
    }
    &-4 {
      min-width: 600px;
      th {
        background-color: $color_red;
        color: #E6E7E8;
        @include below(768px) {
          padding: 6px 10px;
        }
      }
      td {
        text-align: center;
        padding: 10px;
        width: 25%;
        @include below(768px) {
          padding: 6px 10px;
        }
      }
    }
    &-5 {
      th {
        height: 70px;
        padding: 0;
      }
      td {
        height: 130px;
      }
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 80px;
    @include below(719px) {
      gap: 20px;
    }
    li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 290px;
      position: relative;
    }
    &-text {
      min-height: 170px;
      position: relative;
      z-index: 10;
      @include below(719px) {
        min-height: 110px;
      }
    }
    &-img {
      display: block;
      height: fit-content;
      max-width: 300px;

      &-number {
        position: absolute;
        top: -8px;
        left: -14px;
      }
    }
    &-disc {
      list-style-type: disc;
      margin-left: 20px;
    }
    &-number {
      list-style-type: auto;
      margin-left: 20px;
    }
  }
  &__buttons {
    display: flex;
    gap: 20px;
    @include below(600px) {
      flex-wrap: wrap;
    }
    &-center {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  &__faq {
    &-title {
      font-size: 2.3125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3.5625rem;
      color: $color_red;
      margin-bottom: 2.5rem;
      text-align: center;
      @include below(64em) {
        font-size: 2.25rem;
        line-height: 2.8125rem;
        margin-bottom: 1.87rem;
      }
    }
    &-item {
      &-arrow {
        svg {
          width: 0.75rem;
          height: 0.5rem;
          fill: #848484;
          transition: $trns;
        }
      }
      &-wrap {
        margin-bottom: 1.25rem;
        &.show {
          .content__faq-item-arrow {
            svg {
              fill: #E6E7E8;
              transition: $trns;
              transform: rotate(180deg);
            }
          }
          .content__faq-item-item {
            background-color: $color_red;
            transition: $trns;
          }
          h4 {
            color: #E6E7E8;
          }
        }
      }
      &-item {
        background: #F5F7FA;
        transition: $trns;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .88rem 1.25rem;
        cursor: pointer;
        & span {
          margin-left: .62rem;
        }
      }
      &-block {
        display: flex;
        flex-direction: column;
        padding: .88rem 1.25rem;
        background-color: #E6E7E8;
      }
      &-text {
        @include below(55.625em) {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
    }
  }
}
