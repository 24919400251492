@import "main-bx";

.header {
  background-color: $color_red;
  width: 100%;
  height: 70.8px;
  position: fixed;
  z-index: 100;
  @include below(55em) {
  }
  &__top {
    padding: .75rem 0 .69rem;
    .container {
      padding-left: 10px;
      @include below(43.75em) {
        padding-right: 10px;
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
  }
  &__text {
    &-block {
      display: flex;
      margin-right: 1.13rem;
      color: $color_yellow;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.125rem;
      @include below(43.75em) {
        flex-direction: column;
        font-size: .75rem;
        line-height: 170%;
        margin-right: .5rem;
      }
    }
  }
  &__link {
    text-decoration-line: underline;
    font-weight: 700;
    margin-left: .5rem;
    color: $color_yellow;
    @include below(43.75em) {
      margin-left: 0;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    width: 12.1rem;
    flex-shrink: 0;
    &-desktop {
      @include below(43.75em) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @include below(43.75em) {
        display: block;
        width: 4.4375rem;
        height: 3.5625rem;
        margin-right: 1rem;
      }
    }
  }
  &__btn {
    white-space: nowrap;
    @include below(43.75em) {
      white-space: nowrap !important;
    }
  }
}
