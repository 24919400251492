$font_base: sans-serif;
$font_main: "Inter", $font_base;

$color_white: #FFF;
$color_black: #000;
$color_dark: #272727;
$color_red: #AB110E;
$color_blue: #1D585E;
$color_green: #04A207;
$color_yellow: #FFE000;
$color_gray: #C6C6C7;
$color_graydark: #A4A4A4;

$breaks: (
        desktop_lg: 1600px,
        desktop: 1440px,
        laptop: 1380px,
        tablet: 1100px,
        tablet_md: 1024px,
        mobile: 768px,
        mobile_sm: 575px,
        mobile_xs: 479px,
        mobile_xxs: 375px,
);

$container: (
        desktop_lg: 1420px,
        desktop: 1400px,
        laptop: 1024px,
        tablet: 768px,
        mobile: 345px,
        mobile_xs: 280px,
);

$body_width: 1920px;


$trns: all 0.3s linear;
