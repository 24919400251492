@import "main-bx";

.btn {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 45px;
  font-family: $font_base;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.95rem;
  white-space: nowrap;

  text-align: center;
  color: $color_black;
  background-color: $color_yellow;
  border-radius: 1.25rem;
  transition: 0.35s all;
  cursor: pointer;
  @include below(43.75em) {
    font-size: 0.75rem;
    line-height: 0.9375rem;
    padding: 5px 27px;
    white-space: unset;
    width: min-content;
  }

  &-square {
    &_yellow {
      border: 3px solid $color_yellow;
      padding: 10.5px 67px;
      border-radius: 0.375rem;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      color: $color_red;
      background-color: $color_yellow;
      text-align: center;
      @include below(48em) {
        padding: 9.5px 50px;
        width: 100%;
        font-size: 1.375rem;
      }
    }
    &_transparent {
      border: 3px solid $color_yellow;
      border-radius: 0.375rem;
      background-color: transparent;
      color: $color_yellow;
      padding: 10.5px 55px;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
      @include below(48em) {
        padding: 9.5px 37px;
        width: 100%;
        font-size: 1.375rem;
      }
    }
  }

  &-green {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #04A207;
    border-radius: 0.375rem;
    background-color: $color_green;
    color: $color_white;
    filter: drop-shadow(0px 0px 22px #04A207);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    padding: 14px 63px;
    text-align: center;
    @include below(64em) {
      font-size: 1.25rem;
      width: 100%;
    }
    &_transparent {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.375rem;
      border: 3px solid #04A207;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      color: $color_green;
      background-color: transparent;
      padding: 14px 55px;
      text-align: center;
      @include below(64em) {
        font-size: 1.25rem;
        width: 100%;
      }
    }
  }
  &-gray {
    border: 3px solid #A4A4A4;
    border-radius: 6px;
    background-color: #A4A4A4;
    color: $color_white;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    padding: 9px 34px;
    text-align: center;
    text-transform: uppercase;
    @include below(64em) {
      font-size: 1.25rem;
    }
    &_transparent {
      border-radius: 6px;
      border: 3px solid #A4A4A4;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      color: #A4A4A4;
      background-color: transparent;
      padding: 9px 34px;
      text-align: center;
      text-transform: uppercase;
      @include below(64em) {
        font-size: 1.25rem;
      }
    }
  }
}
